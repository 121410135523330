import PropTypes from 'prop-types';
import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link, graphql } from 'gatsby';
import { HeaderPage, Kaltura } from '@bgea-js/design-library';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { Seo } from '../components/Seo';

//idea for optimization, might require <Suspense> wrapper
// const SingleDonation = React.lazy( () => import("@bgea/bgforms-templates/dist/templates/SingleDonation"));

function Page({ data }) {
  let meta = {
    title: 'Billy Graham Evangelistic Association | Gift Planning',
    description:
      'We rely on the prayers and gifts of friends like you. Your support is being used by God to transform lives around the world.',
    socialTitle:
      'Make an online Gift to the Billy Graham Evangelistic Association',
  };
  let styles = {};
  const projects = data?.projects?.nodes
    .filter((item) => item?.frontmatter?.image)
    .map((item) => ({
      ...item?.frontmatter,
      projectId: item?.frontmatter?.projectCode,
    }));
  projects.sort((a, b) => a?.order - b?.order);
  return (
    <Layout
      meta={meta}
      styles={styles}
      header={
        <HeaderPage
          title="Legacy Planning"
          subtitle="Your legacy gifts can help spread the Gospel for generations to come."
          imageWidth={4}
          className='is-hidden-mobile'
        >
          <Kaltura id="1_c0ms74p3" />
          {/* <StaticImage src={'../images/example.jpg'} alt="asdasf" /> */}
        </HeaderPage>
      }
    >
      <Ui.Section className="pt-3 pb-0">
        <Ui.Container>
          <Ui.Breadcrumb>
            <Ui.Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Ui.Breadcrumb.Item>
            <Ui.Breadcrumb.Item active>
              <Link to="#" aria-current="page">
                Legacy Planning
              </Link>
            </Ui.Breadcrumb.Item>
          </Ui.Breadcrumb>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section className="pt-3 pb-0">
        <Ui.Container>
          <hr />
          <Ui.Columns>
            <Ui.Columns.Column>
              <Ui.Content className='pb-6'>


<Ui.Columns vCentered className='has-text-centered'>
<Ui.Columns.Column>
<div className="notification is-info is-stretched">
      <h3>Receive your free Legacy Planner</h3>
      <p>Our Legacy Planner includes practical estate
        information as well as Biblical guidance on wise stewardship. Our Ministry
        Relations staff is available to confidentially discuss your estate planning
        questions.</p>
      <p><a className="button" href="https://lp.billygraham.org/legacy-planning-page/">Receive
          your free Legacy Planner</a></p>
    </div>
  
  </Ui.Columns.Column>
      <Ui.Columns.Column size={7}>


      <Ui.Notification >
      <Ui.Content>
        <p className='subtitle is-centered'>Our Ministry Relations staff is available to serve you. </p>
        <Ui.Columns>
          <Ui.Columns.Column>
            <strong>Contact us:</strong>
            <p>
              1-877-243-2202<br />
              <a href="mailto:donor-ministries@bgea.org">donor-ministries@bgea.org</a>
            </p>
            <p>
              <em>Billy Graham Evangelistic Association</em><br />
              Donor Ministries<br />
              1 Billy Graham Parkway<br />
              Charlotte, NC 28201
            </p>
          </Ui.Columns.Column>
          <Ui.Columns.Column>
            <p><strong>Tax ID Number #45-2588350</strong></p>
            <p><small>BGEA is a 501(c)(3) tax-exempt charity. All gifts are tax deductible to the full extent allowed by law.</small></p>

          </Ui.Columns.Column>
        </Ui.Columns>

      </Ui.Content>

    </Ui.Notification>
      {/* <Kaltura id="1_c0ms74p3" /> */}
                
                {/* <p>Watch this short video to see how you can use our free Legacy Planner to share
                  your faith with loved ones.</p> */}
                  </Ui.Columns.Column>
                </Ui.Columns>

                <h2 className="font-alt">Gift Options</h2>
                <p>
                  Planned gifts are arranged by the donor in the present, but
                  given in the future. These gifts are typically accomplished
                  through a will, trust, or other estate planning tool. {' '}
                  <a href="https://billygraham.org/give/gift-planning/contact-us/#contact-regional-manager">
                    Contact us for more information
                  </a>{' '}
                  to discuss your gift plan options.
                </p>

                <Ui.Columns vCentered className="has-text-centered">
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#annuities">
                      Annuities &amp; Trusts
                    </a>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#property">
                      Personal Property
                    </a>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#estate">
                      Real Estate{' '}
                    </a>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#stocks">
                      Stocks &amp; Securities
                    </a>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#wills">
                      Wills &amp; Estates
                    </a>
                  </Ui.Columns.Column>
                  <Ui.Columns.Column>
                    <a href="https://billygraham.org/give/gift-planning/ways-to-give/#beneficiary">
                      Beneficiary Designations
                    </a>
                  </Ui.Columns.Column>
                </Ui.Columns>

                {/* <Tabs content={[
      {
        label: "Annuities &amp; Trusts",
        content: (
          <div>
            <h2>Annuities &amp; Trusts</h2>
            <h3>Charitable Gift Annuity (CGA)</h3>
            <p>
              A Charitable Gift annuity is a contractual agreement that supports BGEA,
              provides a fixed stream of payments to you and/or a loved one, while
              offering tax benefits. The annuity can be funded with cash or marketable
              securities. Your Gift Annuity will make payments to you for life, at a rate
              determined by your age at the time payments begin. Payments to you may be
              deferred, allowing for a larger charitable tax deduction and larger annuity
              benefits.
            </p>
            <p>
              After you or your loved ones passes, BGEA receives the remainder of your
              original gift. To confidentially explore gift options,{" "}
              <a href="/give/gift-planning/contact-us/#contact-regional-manager">
                contact your ministry representative
              </a>
              , with no obligation.
            </p>
            <div>
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/05/16602-Direct-Mail_2023-Gift-Annuity-Rate-Change-Mailing-1-Rate-Sheet_V2_Hi-Res_WEBSITE.pdf">
                Rate Sheet →
              </a>
              <br />
              <br />
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/10/15643-Direct-Mail-Gift-Annuity-Fillable-Application-V2-3.pdf">
                Charitable Gift Annuity Application →
              </a>
              <br />
              <br />
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/05/GA-Disclosure-Stmt-The-Cross-Fund-2023.pdf">
                Gift Annuity Disclosure Statement →
              </a>
              <br />
              <br />
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/01/INSTRUCTIONS-TO-FUND-YOUR-GIFT-ANNUITY-2022.pdf">
                Instructions to Fund Your Gift Annuity →
              </a>
              <br />
              <br />
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2021/11/13751-Donor-Ministries-2021-Gift-Annuity-Promo-Mailing-1-Brochure_Web.pdf">
                Gift Annuity Brochure →
              </a>
            </div>
            <h3>Charitable Remainder Unitrust</h3>
            <p>
              Significant features of a Charitable Remainder Unitrust include tax
              advantages and a stream of future income for you and/or your loved one. Your
              Unitrust may be funded with cash or appreciated assets such as marketable
              securities or real estate. The Unitrust, however, is an irrevocable
              agreement, meaning that the terms cannot change.
            </p>
            <p>
              The charitable contribution you receive from establishing a Unitrust may be
              claimed in the year it is funded or may be carried over for up to five years
              if it is too large to use in any one year. Where applicable, taxation of
              capital gains on the donated assets are deferred until distributed.
            </p>
            <p>
              Since there are many options to consider when funding a Unitrust you may
              want to{" "}
              <a href="/give/gift-planning/contact-us/#contact-regional-manager">
                contact your ministry representative
              </a>{" "}
              who can confidentially answer your questions or suggest ways to maximize
              your gift. There is no obligation on your part.
            </p>
            <div>
              <a href="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2021/05/Cross-Fund-Irrev-Trust-Disclosure-Statement-2021.pdf">
                Irrevocable Trust Disclosure Statement →
              </a>
            </div>
          </div>

        )
      },
      {
        label: "Personal Property",
        content: (
          <div>
            <h2>Personal Property</h2>
            <p>Your non-cash items, from cars to coins, can be donated to BGEA and used for ministry. Through a partnership with the non-profit iDonate, you can donate assets while potentially saving on taxes as well. <a href="/give/gift-planning/contact-us/#contact-regional-manager">Contact your ministry representative</a> with your questions about donating non-cash assets to BGEA.</p>
            <div>
                  <a href="/give/other-ways-to-give/non-cash-gifts/">Non-Cash Gifts We Accept →</a><br />
                  <br />
                  <a href="/give/other-ways-to-give/non-cash-gifts/">Start a Non-Cash Donation  →</a>
              </div>
          </div>
        )
      },
    ]}/> */}

                <hr />

                <div id="legacy-resources"></div>
                <h2 className="font-alt">Additional Resources</h2>
                <p>
                  You can share your faith and values with the next generation
                  in simple yet powerful ways. Explore these resources as you
                  intentionally and prayerfully take steps that will establish a
                  rich spiritual legacy; and one day, you will hear your
                  Heavenly Father say,{' '}
                  <em>“Well done, good and faithful servant”</em>&nbsp; (Matthew
                  25:21 ESV).
                </p>
                <p>
                  Leaving a lasting legacy involves many areas of your life, not
                  just financial giving. Explore these resources as you
                  intentionally and prayerfully take steps that will establish a
                  rich spiritual legacy that may touch generations to come. {' '}
                  <a href="https://billygraham.org/give/gift-planning/contact-us/#contact-regional-manager">
                    Contact us for more information
                  </a>{' '}
                  to discuss your gift plan options.
                </p>
                <p>
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2020/05/Donor-Ministries-Writing-Your-Christian-Testimony.pdf">
                      Writing Your Christian Testimony →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/sites/2/2018/05/BGEA-Donor-Ministries-Worksheets-Memorial-Stones.pdf">
                      Sharing Your Story of God’s Faithfulness →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/sites/2/2018/10/Oct-DonMin-Worksheets-Pastor.pdf">
                      Praying for Your Pastor →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/2020/05/BGEA-Donor-Ministries-Worksheets-Forgiveness.pdf">
                      Writing a Letter of Reconciliation →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/sites/2/2018/07/BGEA-Donor-Ministries-Why-Behind-Will-Worksheet.pdf">
                      Sharing Your Faith and Values Through Your Will →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/sites/2/2018/03/BGEA-Donor-Ministries-Worksheets-Mentoring.pdf">
                      Mentoring the Next Generation →
                    </a>
                  </strong>
                  <br />
                  <strong>
                    <a href="https://static.billygraham.org/sites/billygraham.org/uploads/pro/sites/2/2018/09/07376-DonMin-Worksheets-Tribute-Letter.pdf">
                      Honoring a Loved One →
                    </a>
                  </strong>
                </p>
                <p>
                  The information on this website is not intended as legal or
                  tax advice. For such assistance, please consult your attorney
                  or financial adviser.
                </p>
              </Ui.Content>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query {
    projects: allMdx {
      nodes {
        body
        fields {
          slug
        }
        frontmatter {
          title
          slug

          description
          projectCode
          order
        }
      }
    }
  }
`;

export default Page;

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title="Billy Graham Evangelistic Association | Giving"
      description="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
      pathname={location.pathname}
    />
  );
};
